import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import React from 'react';

export const defaultLocale = 'en';

export const LocalizationProvider = ({ children }: { children: React.ReactNode }) => {
  i18n
    .use(ChainedBackend)
    .init({
      lng: defaultLocale,
      fallbackLng: defaultLocale,
      interpolation: {
        escapeValue: false,
      },
      resources: {
        en: {
          translation: {
            'error.branch.duplicate': 'Branch with the same name already exists.',
            'error.branch.invalid.name': 'Invalid name for branch. Branch names must contain only letters, numbers, spaces, brackets, dashes, underscores, parenthesis, commas and single quotes.',
            'error.branch.parent.missing': 'Parent branch does not exist.',
            'error.branch.headcommitcheckfailed': 'Branch you are working on has been modified externally. Please refresh the page.',
            'error.tag.duplicate': 'Tag already exists.',
            'error.tag.no.content.to.tag': 'Branch cannot be tagged as there is no content in the ruleset.',
            'error.tag.invalid.branch': 'Branch to be tagged does not exist.',
            'error.default': 'This action cannot be completed. Please contact underwriteme if the issue persists.',
            'error.branch.missing': 'Missing branch.',
            'error.merge.commit.not.found': 'Commit for merge not found.',
            'error.conflict.wrapup.line.order': 'There is a conflict with the wrap up lines execution order and this branch cannot be merged. Please resolve this manually.',
            'error.conflict.unmergeable': 'There are conflicts which cannot be resolved. Please resolve manually.',
            'error.conflict.invalid.resolutions': 'Resolutions provided for this merge is invalid. Check resolutions.',
            'error.merge.out.of.date.head.commit': 'Please pull changes first, source branch is changed recently.',
            'error.branch.protected.branch.violation': 'A violation has occurred when trying to update this protected branch : {{errorMessage}}',
            'push-validation-errors': 'There are validation errors in this ruleset. Please resolve before pushing to a public branch.',
            'error.validation.export': 'There are validation errors in this ruleset. Please resolve before exporting.',
            'error.validation.import': 'There are validation errors in this ruleset. Please resolve before importing.',
            'error.validation.line.test': 'There are validation errors in this ruleset. Please resolve before running line test.',
            'error.not.found.line.test': 'There was an error while running this test, either the file or test could not be found.',
            'error.not.saved.changes.line.test': 'You have unsaved changes. Please save your changes before running the unit test',
            'error.not.supplied.upload.file.line.test': 'Sheet required',
            'error.merge.merge.conflicts': 'Merge conflicts detected. Please resolve them first.',
            'validation.error.locale.already.exists': 'Locale with given name already exists.',
            'validation.error.locale.invalid.name': 'Names must start with a capital letter and may contain only letters, numbers and underscores.',
            'validation.error.bucket.missing': 'Bucket does not exist',
            'validation.error.question.already.exists': 'Question with given name already exists.',
            'validation.error.question.invalid.name': 'Names must start with a capital letter and may contain only letters, numbers and underscores.',
            'validation.error.question.missing': 'Question does not exist',
            'validation.error.enquiryLine.missing': 'Enquiry line does not exist',
            'validation.error.optionList.already.exists': 'An asset with this name already exists. All names must be unique',
            'validation.error.optionList.invalid.name': 'Option list names must start with a capital letter and may contain only letters, numbers and underscores.',
            'validation.error.optionList.option.invalid.name': 'Failed to add Option \'{{optionName}}\' to Option List \'{{optionListName}}\' - \'{{optionName}}\' is an invalid name. Names must start with a capital letter and may contain only letters, numbers and underscores.',
            'validation.error.optionList.option.duplicate.order': 'Option \'{{optionName}}\' has duplicate order.',
            'validation.error.optionList.option.duplicate.name': 'Option \'{{optionName}}\' already exists. All Option names in a list must be unique.',
            'validation.error.optionList.option.missing.default.locale': 'Option \'{{optionName}}\' has no text for the default locale.',

            'validation.error.excel.panel.header': 'Errors in spreadsheet',
            'validation.error.excel.optionList.header.required': '\'{{name}}\' column is missing',

            'validation.error.excel.optionList.already.exists': 'An asset with this name already exists. All names must be unique',
            'validation.error.excel.optionList.option.name.duplicate': '<b>{{column}}{{row}}</b> \'{{name}}\' Option name is already used',
            'validation.error.excel.optionList.option.name.required': '<b>{{column}}{{row}}</b> Option name is missing',
            'validation.error.excel.optionList.option.name.invalid': '<b>{{column}}{{row}}</b> \'{{name}}\' is an invalid option name. Names must start with a capital letter and may contain only letters, numbers and underscores.',
            'validation.error.excel.optionList.option.defaultLocale.required': '<b>{{column}}{{row}}</b> \'{{name}}\' Option must have a text value for the default locale',

            'validation.locale.earliest.date': 'The earliest date allowed for this question is $lowerBound.',
            'validation.locale.latest.date': 'The latest date allowed for this question is $upperBound.',
            'validation.locale.future.date': 'Please enter a date in the future.',
            'validation.locale.past.date': 'Please enter a date in the past.',
            'validation.locale.invalid.date': 'Please enter a valid date.',
            'validation.locale.minimum.number': 'The minimum answer allowed for this question is $lowerBound.',
            'validation.locale.maximum.number': 'The maximum answer allowed for this question is $upperBound.',
            'validation.locale.whole.number': 'Please enter a whole number.',
            'validation.locale.invalid.number': 'Please enter a number.',
            'validation.locale.invalid.option': '$answer is not a valid answer for this question.',
            'validation.locale.maximum.characters': 'The maximum number of characters is $maxLength.',
            'validation.error.table.invalid.name': 'Invalid name for table (this is the name of the excel file without the extension). Names must start with a capital letter and may contain only letters, numbers and underscores.',
            'validation.error.asset.already.exists': 'An asset given name already exists.',
            'validation.error.import': 'Definition being imported has validation errors. Please import into a private branch and edit the rules to fix the issue.',
            'validation.error.asset.line.tests.not.exists': 'Line Test does not exist',
            'validation.error.asset.line.tests.spreadsheet.errors': 'Line Test Spreadsheet contains errors, please fix issues shown in red on the downloaded spreadsheet.',
            'validation.error.asset.line.not.exists': 'Enquiry Line does not exist',
            'validation.error.branch.structural.not.allowed': 'You are not allowed to update a structural branch',

            // FormModal Common
            'form.modal.submit.label': 'Submit',
            'form.modal.cancel.label': 'Cancel',

            // user management
            'validation.error.user.email.must.be.email': 'Email must be a valid email address.',
            'validation.error.user.email.in_use': 'There is already a user with the same email address.',
            'validation.error.user.role.invalid': 'A role has been selected that cannot be applied to this user: {{errorMessage}}',
            'resetpassword.modal.title': 'Reset password',
            'resetpassword.modal.success': 'Email sent.',
            'resetpassword.modal.text': 'An email will be sent to reset this password.',

            'usermanagement.create.modal.title': 'Create new user',
            'usermanagement.create.modal.email.label': 'Username:',
            'usermanagement.create.modal.email.placeholder': 'Must be valid email address',
            'usermanagement.create.modal.email.validation.text': 'Please input an email address',
            'usermanagement.create.modal.languagetag.label': 'Language Tag:',
            'usermanagement.create.modal.languagetag.validation.text': 'Please select a language',
            'usermanagement.create.modal.roles.label': 'Roles:',
            'usermanagement.create.modal.roles.validation.text': 'Please select some roles',
            'usermanagement.create.modal.success': 'User {{createdUsername}} created',

            'usermanagement.update.modal.title': 'Update user',
            'usermanagement.update.modal.email.label': 'Username:',
            'usermanagement.update.modal.email.placeholder': 'Must be valid email address',
            'usermanagement.update.modal.languagetag.label': 'Language Tag:',
            'usermanagement.update.modal.languagetag.validation.text': 'Please select a language',
            'usermanagement.update.modal.roles.label': 'Roles:',
            'usermanagement.update.modal.roles.validation.text': 'Please select some roles',
            'usermanagement.update.modal.success': 'User {{updatedUsername}} updated',

            'usermanagement.delete.modal.title': 'Delete User',
            'usermanagement.delete.modal.text': 'Are you sure you want to delete user',
            'usermanagement.delete.modal.success': 'User deleted.',
            'usermanagement.delete.modal.error': 'Delete failed due to an error.',

            'usermanagement.grid.column.username.title': 'Username',
            'usermanagement.grid.column.languagetag.title': 'Language Tag',
            'usermanagement.grid.column.roles.title': 'Roles',

            // consent form
            'consent.form.tracking.title': 'Update Consent',
            'consent.form.tracking.enable.label': 'Enable tracking',
            'consent.form.tracking.button.save.text': 'Save',
            'consent.form.tracking.modal.title': 'Save Changes',
            'consent.form.tracking.modal.success': 'Consent updated.',
            'consent.form.tracking.modal.content': 'Are you sure you want to update the consent?',

            // todo add enquiry line based errors here

            // Upload
            'error.upload.xlsx.format.not.valid': 'Cannot import file as it is not a valid export in xlsx or xls format',
            'error.upload.xlsx.foreach.validation': 'Cannot import foreach file as it has validation errors. Please use the foreach template and try again.',
            'error.upload.xlsx.foreach.file.invalid': 'Cannot import file as it is not a valid foreach file. Please use the foreach template and try again.',
            'error.upload.xlsx.foreach.max.gen.exceeded': 'Cannot import file as the number of generated test cases has exceeded the maximum limit set. Please reduce the potential number of test cases that can be generated and try again.',

            // Translations
            'translation.success': 'Translation imported successfully',
            'warn.translation.unrecognised.assets': 'Your import file contains assets that do not exist in your private branch. These additional assets have not been imported.',
            'warn.translation.unrecognised.locales': 'Your import file contains locales that do not exist in your private branch. These additional locales have not been imported.',
            'warn.translation.unrecognised.locales.and.assets': 'Your import file contains locales and assets that do not exist in your private branch. These additional locales and assets have not been imported.',

            // RALT
            'line.tests.run.button': 'Run tests',
            'line.tests.run-all-tests.button.label': 'Run Tests',
            'line.tests.run.modal.title': 'Run tests',
            'line.tests.run.modal.branch.label': 'Private Branch:',
            'line.tests.run.modal.branch.validation.warn': 'Please select a branch',
            'line.tests.run.success': 'We are currently processing all line tests for the branch {{branchName}}.<p></p>This will take several minutes to process. Please check the main page for the current status of the reports.',
            'line.tests.export.modal.title': 'Export test summary',
            'line.tests.export.modal.content': 'Results will be exported as an Excel file.<p></p>This operation might take a while for rule sets with a large set of tests, so please do not close or refresh the browser.',
            'line.tests.grid.column.name.title': 'Name',
            'line.tests.grid.column.date.title': 'Date',
            'line.tests.grid.column.branch.title': 'Branch',
            'line.tests.grid.column.user.title': 'User',
            'line.tests.grid.column.summary.title': 'Summary',
            'line.tests.grid.column.test.result.title': 'Test Result',
            'line.tests.grid.column.status.title': 'Status',
            'line.tests.grid.column.export.title': 'Export',
            'line.tests.grid.export.tooltip.title': 'Export run results',
            'line.tests.grid.summary.text.passed': 'All tests have passed.',
            'line.tests.grid.summary.text.failed': 'There are errors. Please download the report to view the failed tests',
            'line.tests.grid.summary.text.running': 'In Progress...',
            'line.tests.grid.summary.text.no.tests': 'There are no tests to run.',
            'line.tests.grid.summary.text.invalid.status': 'Invalid status. Please contact underwriteme if the issue persists.',
            'line.tests.grid.status.running.label': 'Running...',
            'line.tests.grid.status.passed.label': 'Passed',
            'line.tests.grid.status.failed.label': 'Failed',
            'line.tests.grid.status.invalid.label': 'Invalid',
            'line.tests.grid.status.none.label': 'None',
            'line.tests.grid.status.completed.warning': '-WARNING',
            'line.tests.panel.title': 'Unit Tests',
            'line.tests.template.download': 'Template',
            'line.tests.template.button.blank': 'Blank',
            'line.tests.template.button.foreach': 'For Each',
            'line.tests.upload.button.label': 'Upload',
            'line.tests.upload.modal.title': 'Add Unit Test',
            'line.tests.upload.modal.excel.label': 'Sheet',
            'line.tests.upload.modal.forEach.label': 'For each',
            'line.tests.delete.modal.title': 'Are you sure you want to delete this unit test?',
            'line.tests.delete.modal.cancel.label': 'No',
            'line.tests.delete.modal.ok.label': 'Yes',

            // History
            'history.grid.column.date.title': 'Date',
            'history.grid.column.user.title': 'User',
            'history.grid.column.message.title': 'Message',
            'history.grid.column.tags.title': 'Tags',
            'history.grid.column.export.title': 'Export',
            'history.grid.export.button.tooltip': 'Export this commit',
            'history.grid.export.modal.title': 'Export Commit',
            'history.grid.export.modal.content': 'Rules for selected commit will be exported as a zip file. You can use this zip file as an import.<p></p>This operation might take a while for large rulesets.',

            // Commit Summary
            'history.commit.summary.grid.column.asset.action.title': 'Action Type',
            'history.commit.summary.grid.column.asset.type.title': 'Asset Type',
            'history.commit.summary.grid.column.asset.name.title': 'Asset Name',
            'history.commit.summary.grid.column.user.action.title': 'User Action',

            // Deployments
            'deployment.create.tag.button': 'New Tag',
            'deployment.create.tag.modal.title': 'New Tag',
            'deployment.create.tag.modal.name.label': 'Tag Name:',
            'deployment.create.tag.modal.name.warn': 'Please input a name',
            'deployment.create.tag.modal.success': 'Tag {{tagName}} created.',
            'deployment.export.tag.modal.title': 'Export Tag',
            'deployment.export.tag.modal.content': 'Rules for selected tag will be exported as a zip file. You can use this zip file as an import.<p></p>This operation might take a while for large rulesets.',
            'deployment.grid.column.export.title': 'Export',
            'deployment.grid.export.button.tooltip': 'Export this tag',
            'deployment.grid.column.tag.title': 'Tag',
            'deployment.grid.column.created.title': 'Created',
            'deployment.history.modal.title': 'Deployment History',
            'deployment.history.grid.column.date.title': 'Date',
            'deployment.history.grid.column.user.title': 'User',
            'deployment.history.grid.column.action.title': 'Action',
            'deployment.update.state.modal.title': 'Update Deployment State',
            'deployment.update.state.modal.content': 'Are you sure you wish to <b>{{status}}</b> this tag?',
            'deployment.update.state.modal.content.void': 'void',
            'deployment.update.state.modal.content.deploy': 'deploy',
            'deployment.update.state.modal.success': 'Success.',
            'deployment.status.live': 'LIVE',
            'deployment.status.pending': 'PENDING',
            'deployment.status.voided': 'VOIDED',

            // Editor
            // Line
            'editor.line.wrapup.order.drawer.title': 'Wrap-up lines order',
            'editor.line.wrapup.order.button': 'Order',
            'editor.line.menu.unnamed.line.name': 'Unnamed Line',
            'editor.line.menu.search.tooltip.title': 'Graph Search',
            'editor.line.menu.search.popover.placeholder': 'Search...',
            'editor.line.menu.button.export': 'Export Image',
            'editor.line.menu.button.questions': 'Questions',
            'editor.line.menu.button.tests': 'Unit Tests',
            'editor.line.menu.button.tables': 'Tables',
            'editor.line.menu.button.properties': 'Properties',

            // Questions Drawer
            'editor.line.questions.drawer.title': 'Available Questions',
            'editor.line.questions.drawer.grid.node.title': 'Node',
            'editor.line.questions.drawer.grid.name.title': 'Name',
            'editor.line.questions.drawer.grid.text.title': 'Question Text',
            'editor.line.questions.drawer.grid.answers.title': 'Answers',
            'editor.line.questions.drawer.grid.help.title': 'Help Text',
            'editor.line.questions.drawer.grid.type.title': 'Type',

            // Modal
            // Save Commit
            'modal.save.changes.title': 'Save Changes',
            'modal.save.changes.comment.label': 'Comment:',
            'modal.save.changes.comment.placeholder': 'Optional commit message',

            // Link Modal
            'modal.link.title': 'Link',
            'modal.link.text.missing.message': 'Expression required',

            // Branch Node Modal
            'modal.branch.node.title': 'Branch Node',
            'modal.branch.node.asset.empty.error.message': 'Query asset required',
            'modal.branch.node.placeholder': 'Start typing for auto-complete',

            // Static Trigger Node Modal
            'modal.static.trigger.node.title': 'Rule Trigger Node - Static',
            'modal.static.trigger.node.line.label': 'Line:',
            'modal.static.trigger.node.line.empty.message': 'Line required',
            'modal.static.trigger.node.tag.label': 'Tag:',
            'modal.static.trigger.node.line.placeholder': 'Start typing for auto-complete',

            // Dynamic Trigger Node Modal
            'modal.dynamic.trigger.node.title': 'Rule Trigger Node - Dynamic',
            'modal.dynamic.trigger.node.question.label': 'Question:',
            'modal.dynamic.trigger.node.question.missing': 'Question required',
            'modal.dynamic.trigger.node.question.placeholder': 'Start typing for auto-complete',

            // Contributions Modal
            'modal.contributions.title': 'Contributions',
            'modal.contributions.bucket.label': 'Bucket',
            'modal.contributions.bucket.missing': 'Bucket required',
            'modal.contributions.value.label': 'Value',
            'modal.contributions.value.missing': 'Value required',
            'modal.contributions.button.add': 'Add contribution',

            // Table Modal
            'modal.table.title': 'Table',
            'modal.table.label': 'Table:',
            'modal.table.missing': 'Table required',

            // Create Public Branch Modal
            'modal.createPublicBranch.title': 'Create Public Branch',
            'modal.createPublicBranch.name.label': 'Public Branch Name',
            'modal.createPublicBranch.name.required': 'Please input a name',
            'modal.createPublicBranch.success': 'Public Branch {{branchName}} created',

            // Create Private Branch Modal
            'modal.createPrivateBranch.title': 'New Private Branch',
            'modal.createPrivateBranch.name.label': 'Branch Name',
            'modal.createPrivateBranch.name.required': 'Please input a name',
            'modal.createPrivateBranch.success': 'Private branch {{branchName}} created',

            // Fork Public Branch Modal
            'modal.forkPublicBranch.title': 'Fork Public Branch',
            'modal.forkPublicBranch.name.label': 'Public Branch Name',
            'modal.forkPublicBranch.name.required': 'Please input a name',
            'modal.forkPublicBranch.success': 'Public Branch {{branchName}} forked',

            // Common
            'button.save': 'Save',
            'button.cancel': 'Cancel',
            'message.saved': 'Saved',

            // Assets
            // Option Lists
            'option.title.create': 'Creating option list',
            'option.title.edit': 'Editing option list',
            'option.save.button': 'Save',
            'option.import.button': 'Import',
            'option.export.button': 'Export',
            'option.label.name': 'Name',
            'option.modal.import.title': 'Import Options',
            'option.modal.import.label': 'Please select a file',
            'option.grid.column.order.title': 'Order',
            'option.grid.column.name.title': 'Name',
            'option.grid.column.text.title': 'Text ({{locale}})',
            'option.grid.column.triggers.title': 'Triggers',
            'option.grid.column.tags.title': 'Tags',
            'option.grid.column.categories.title': 'Categories',

            // Locale
            'locale.label.name': 'Name',
            'locale.label.description': 'Description',
            'locale.label.language.code': 'Language Code',
            'locale.label.country.code': 'Country Code',
            'locale.label.fallback.locale': 'Fallback locale',
            'locale.label.date.format': 'Date format',
            'locale.label.is.default': 'Is Default',
            'locale.label.earliest.date': 'Earliest date',
            'locale.label.latest.date': 'Latest date',
            'locale.label.future.date': 'Future date',
            'locale.label.past.date': 'Past date',
            'locale.label.invalid.date': 'Invalid date',
            'locale.label.minimum.number': 'Minimum number',
            'locale.label.maximum.number': 'Maximum number',
            'locale.label.whole.number': 'Whole number',
            'locale.label.invalid.number': 'Invalid number',
            'locale.label.invalid.option': 'Invalid option',
            'locale.label.maximum.characters': 'Maximum characters',

            // Table
            'table.warning.save.line.first.text': 'You need to save this enquiry line first to add any tables.',
            'table.warning.save.line.first.modal.close.button.text': 'OK',
            'table.delete.used.nodes.confirm.title': 'Please remove all usages of {{tableName}} in the graph before deleting it.',
            'table.delete.used.nodes.confirm.cancel': 'Cancel',
            'table.delete.used.nodes.confirm.ok': 'See usage',
            'table.delete.confirm.title': 'Are you sure you want to delete this table?',
            'table.delete.confirm.cancel': 'No',
            'table.delete.confirm.ok': 'Yes',
            'table.add.title': 'Add Table',
            'table.add.sheet.label': 'Sheet',
            'table.add.sheet.label.required': 'Sheet required',
            'table.add.sheetAxis.label': 'Sheet axis:',
            'table.list.tables': 'Tables',
            'table.list.tables.column.name.title': 'Name',
            'table.list.tables.column.sheetAxis.title': 'Sheet Axis',

            // Question
            'question.create.title': 'Creating question',
            'question.update.title': 'Editing question',
            'question.form.name.label': 'Name',
            'question.form.type.label': 'Type',
            'question.form.tags.label': 'Tags',
            'question.form.multivalued.label': 'Multi-valued',
            'question.form.isglobal.label': 'Is Global',
            'question.form.maxlength.label': 'Max length',
            'question.form.lowerbound.label': 'Lower bound',
            'question.form.upperbound.label': 'Upper bound',
            'question.form.roundingpreference.label': 'Rounding Preference',
            'question.form.optionlist.label': 'Option list',
            'question.form.includedcategories.label': 'Included categories',
            'question.form.excludedcategories.label': 'Excluded categories',
            'question.form.minsearchchars.label': 'Min search characters',
            'question.form.islenient.label': 'Is Lenient',
            'question.form.defaultoption.label': 'Default option',
            'question.form.locale.text.label': 'Text',
            'question.form.locale.helptext.label': 'Help text',

            // Bucket
            'bucket.create.title': 'Creating bucket',
            'bucket.update.title': 'Editing bucket',
            'bucket.form.name.label': 'Name',
            'bucket.form.type.label': 'Type',
            'bucket.form.optionListName.label': 'Option List Name',
            'bucket.form.groupedBuckets.label': 'Grouped Buckets',
            'bucket.form.visibleWhen.label': 'Visible When',

            // Editor Validations
            'editor.validation.button': 'Validation',
            'editor.validation.panel.title': 'Validation',
            'editor.validation.panel.run.button': 'Run Validation',
            'editor.validation.panel.table.name': 'Name',
            'editor.validation.panel.table.problem': 'Problem',
            'editor.validation.panel.title.enquiryDefinition': 'Enquiry Definition',
            'editor.validation.panel.title.enquiryLines': 'Enquiry Lines',
            'editor.validation.panel.title.wrapUpLines': 'Wrap-up Lines',
            'editor.validation.panel.title.bucket': 'Buckets',
            'editor.validation.panel.title.optionLists': 'Option Lists',
            'editor.validation.panel.title.questions': 'Questions',
            'editor.validation.panel.title.tables': 'Tables',
            'editor.validation.panel.title.locales': 'Locales',
            'editor.validation.panel.title.priorEnquiries': 'Prior Enquiry Channels',

            // History
            'history.enquiry.not.found': 'Enquiry with id \'{{enquiryId}}\' not found.',

            // Shell
            'shell.enquiry.not.found': 'Enquiry with id \'{{enquiryId}}\' not found.',

            // Error page
            'error.page.text': 'Sorry, an error has occurred. Please try refreshing the page or go to homepage if the issue persists.',

            // Unauthorised page
            'unauthorised.page.text': 'Sorry, you are not authorized to view this page. You can try logging out and logging in with a different user.',

            // Components
            // ContributionValues
            'contributionValue.duration': ' for ',
          },
        },
      },
    });

  return (
    <I18nextProvider i18n={i18n}>
      {children}
    </I18nextProvider>
  );
};
