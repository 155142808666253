/* eslint-disable react/jsx-props-no-spreading */
import {
  Form, FormInstance, FormProps, message, Modal, ModalProps,
} from 'antd';
import { MessageApi } from 'antd/lib/message';
import React, {
  FC, PropsWithChildren, ReactNode, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { APIErrorResponse, isAPIError } from '../../services/types';
import { useErrorCatcher } from '../error-handler/ErrorHandler';
import { useMetrics } from '../../services/metrics/MetricsProvider';
import { MetricsCollector } from '../../services/metrics/types';

const defaultErrorParser = (catchError: (err: Error) => void, err: Error) => {
  if (isAPIError(err)) {
    return err.response?.data || {} as APIErrorResponse;
  }
  catchError(err);
  return {} as APIErrorResponse;
};

/** A utility object for easier access and interaction with a FormModal */
export interface FormModalState<Data> {
  /** Used for generating tracking events */
  trackingPrefix?: string;
  /** Current form data */
  data: Partial<Data>;
  /** Method to set current form data */
  setData: (data: Data) => void;
  /** Current modal visibility */
  visible: boolean;
  /** Current modal close button disabled flag */
  closeDisabled: boolean;
  /** Method to set FormModal close button disable state */
  setCloseDisabled: (disabled : boolean) => void;
  /** Method to close the modal */
  close: () => MessageApi;
  /** Method to open the modal */
  open: (initialData?: Partial<Data>) => void;
  /** Form instance */
  form: FormInstance<Data>;
  /** FormModal submission state */
  submitting: boolean;
  /** Method to set FormModal submission state */
  setSubmitting: (submitting: boolean) => void;
  /** Internal callback */
  handleCancel: (state: FormModalState<Data>) => void;
  /** Internal callback */
  handleSubmit: (data: any, state: FormModalState<Data>) => Promise<any>;
  /** Internal callback */
  handleSuccess: (response: any, state: FormModalState<Data>) => void;
  handleError: (error: Error) => void;
  setShouldTriggerSubmit: (value: boolean) => void;
  shouldTriggerSubmit: boolean;
}

export interface FormModalStateConfig<Data, Response = any> {
  /** Used for generating tracking event names */
  trackingPrefix?: string;
  /** Initial data to display in the form */
  initialData?: Partial<Data>;
  /** Callback for when the modal was canceled */
  onCancel?: (state: FormModalState<Data>) => void;
  /** Callback for when the form was submitted, must return a promise that resolves on successful submission and rejects on error */
  onSubmit?: (data: Data, state: FormModalState<Data>) => Promise<Response>;
  /** Callback for when the form submission succeeded */
  onSuccess?: (response: Response, state: FormModalState<Data>) => void;
  /** Callback for when a form submission error occurred */
  onError?: (apiError: APIErrorResponse) => void;
  /** Callback for overriding the default error handling behaviour */
  overrideHandleError?: (apiError: APIErrorResponse) => void;
  /** Flag to control whether errors are shown in the form or not. Set to false if missing */
  showErrorsOnForm?: boolean;
}

/** A hook to retrieve a FormModalState instance to pass to a FormModal */
export const useFormModalState = <Data extends any, Response extends any = any>({
  trackingPrefix,
  initialData: initial = {},
  onCancel = () => {},
  onSubmit = () => Promise.reject(),
  onSuccess = () => {},
  onError,
  overrideHandleError,
  showErrorsOnForm = true,
}: FormModalStateConfig<Data, Response> = {}): FormModalState<Data> => {
  const catchError = useErrorCatcher();
  const [data, setData] = useState<Partial<Data>>({});
  const [visible, setVisible] = useState(false);
  const [closeDisabled, setCloseDisabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [shouldTriggerSubmit, setShouldTriggerSubmit] = useState(false);
  const [form] = Form.useForm<Data>();
  const { t } = useTranslation();
  let metrics: MetricsCollector;
  if (trackingPrefix) {
    metrics = useMetrics().metrics;
  }

  const close = useCallback(() => {
    setVisible(false);
    setSubmitting(false);
    setCloseDisabled(false);
    return message;
  }, [setVisible, setSubmitting]);

  const open = useCallback((initialData?: Partial<Data>) => {
    const mergedInitialData = { ...initial, ...initialData };
    form.setFieldsValue(mergedInitialData as any);
    setData(mergedInitialData);
    setVisible(true);
    setCloseDisabled(false);
  }, [setData, setVisible]);

  const handleCancel = useCallback((state: FormModalState<Data>) => {
    onCancel(state);
    setVisible(false);
    if (trackingPrefix) {
      metrics.onTrackEvent(`${trackingPrefix} Modal Cancel Button Clicked`);
    }
  }, [onCancel, setVisible]);

  const handleSubmit = useCallback((submitData: Data, state: FormModalState<Data>) => {
    if (trackingPrefix) {
      metrics.onTrackEvent(`${trackingPrefix} Modal Submit Button Clicked`);
    }
    return onSubmit(submitData, state);
  }, [onSubmit]);

  const handleSuccess = useCallback((response: Response, state: FormModalState<Data>) => {
    setVisible(false);
    setCloseDisabled(false);
    if (trackingPrefix) {
      metrics.onTrackEvent(`${trackingPrefix} Modal Success`);
    }
    onSuccess(response, state);
  }, [onSuccess, setVisible]);

  const defaultErrorHandler = (apiError: APIErrorResponse) => {
    if (!apiError.validations?.length) {
      message.error(t(apiError.error, apiError.error, { errorMessage: apiError.message }), 5);
    } else if (showErrorsOnForm) {
      form.setFields(apiError.validations.map((validation) => (
        { name: validation.target, errors: [t(validation.message, validation.message, validation.attributes || undefined)] }
      )));
    } else {
      message.error(t(apiError.validations[0].message, apiError.validations[0].message, apiError.validations[0].attributes));
    }
  };

  const handleError = (error: Error) => {
    setCloseDisabled(false);
    const apiError: APIErrorResponse = defaultErrorParser(catchError, error);
    if (overrideHandleError) {
      overrideHandleError(apiError);
    } else {
      defaultErrorHandler(apiError);
    }
    onError?.(apiError);
  };

  return {
    trackingPrefix,
    data,
    setData,
    visible,
    closeDisabled,
    setCloseDisabled,
    close,
    open,
    form,
    submitting,
    setSubmitting,
    handleCancel,
    handleSubmit,
    handleSuccess,
    handleError,
    setShouldTriggerSubmit,
    shouldTriggerSubmit,
  };
};

export interface FormModalProps {
  /** FormModalState object.
   *  Overrides some other props.
   */
  state: FormModalState<any>;
  /** Modal visibility, overridden by `state`.
   * Overridden by `state`.
   */
  visible?: boolean;
  /** Initial form values, overridden by `state`.
   * Overridden by `state`.
   */
  initialValues?: any;
  /** Modal title */
  title: ReactNode;
  /** Modal submit button text title.
   * @default 'Submit'
   */
  submitText?: string;
  /** Modal cancel button text title
   * @default 'Cancel'
  */
  cancelText?: string;
  /** Modal width */
  width?: string;
  /** A flag to keep the form data after a form was submitted or cancelled */
  keepData?: boolean;
  /** Overrides for the Modal component props.
   * !Use with caution!
   */
  modalOverrides?: ModalProps;
  /** Overrides for the Form component props.
   * !Use with caution!
   */
  formOverrides?: FormProps;
}

const FormModal: FC<PropsWithChildren<FormModalProps>> = ({
  children,
  state,
  visible,
  initialValues,
  title,
  submitText,
  cancelText,
  width,
  keepData,
  modalOverrides,
  formOverrides,
}) => {
  const [form] = Form.useForm(state?.form);
  const [submitting, setSubmitting] = useState(false);

  const { t } = useTranslation();
  const submitTextOrDefault = submitText || t('form.modal.submit.label');
  const cancelTextOrDefault = cancelText || t('form.modal.cancel.label');

  const handleCancel = () => {
    state.handleCancel(state);
    if (!keepData) {
      form.resetFields();
    }
  };

  const handleSubmit = () => {
    state.setShouldTriggerSubmit(false);
    const setSubmit = state ? state.setSubmitting : setSubmitting;
    setSubmit(true);
    form.validateFields()
      .then(() => {
        (state.handleSubmit(state.data, state))
          .then((response) => {
            state.handleSuccess(response, state);
            if (!keepData) {
              form.resetFields();
              if (state) {
                state.setData({});
              }
            }
          })
          .catch((err) => {
            state.handleError(err);
          })
          .then(() => {
            setSubmit(false);
          });
      })
      .catch(() => {
        setSubmit(false);
      });
  };

  useEffect(() => {
    if (state.shouldTriggerSubmit) {
      handleSubmit();
    }
  }, [state.shouldTriggerSubmit]);

  const valueChangeHandler = (changed: any, all: any) => {
    if (state) {
      state.setData({ ...state.data, ...all });
    }
  };

  return (
    <Modal
      visible={state ? state.visible : visible}
      title={title}
      okText={submitTextOrDefault}
      cancelText={cancelTextOrDefault}
      cancelButtonProps={{ disabled: state ? state.closeDisabled : false }}
      confirmLoading={state ? state.submitting : submitting}
      width={width}
      onCancel={handleCancel}
      onOk={handleSubmit}
      {...modalOverrides}
    >
      <Form
        form={form}
        initialValues={state ? undefined : initialValues}
        onValuesChange={valueChangeHandler}
        onFinish={() => {
          handleSubmit();
        }}
        {...formOverrides}
      >
        {children}
      </Form>
    </Modal>
  );
};

export default FormModal;
