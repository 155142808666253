import { AnalyticsBrowser, User } from '@segment/analytics-next';
import {
  MetricsCollector, UserAttributes,
} from './types';

const traitsWithoutPermissions = (user: User) => Object.fromEntries(Object.entries(user.traits() as object).filter(([key]) => key !== 'permissions'));

class SegmentMetricsCollector implements MetricsCollector {
  private readonly analytics: AnalyticsBrowser;

  constructor(analytics: AnalyticsBrowser) {
    this.analytics = analytics;
  }

  onPageLoaded(category: string, name: string): void {
    this.analytics.user().then((user) => this.analytics.page(category, name, { ...traitsWithoutPermissions(user) }).then());
  }

  onUserIdentified(userId?: string, attributes?: UserAttributes): void {
    this.analytics.identify(userId, attributes).then();
  }

  onTrackEvent(event: string, attributes?: Record<string, string>): void {
    this.analytics.user().then((user) => {
      this.analytics.track(event, { ...attributes, ...traitsWithoutPermissions(user) }).then();
    });
  }
}

export default SegmentMetricsCollector;
